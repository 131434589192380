<template>
    <div>
        <CRow>
            <CCol md="12">
                <CCard>
                    <CCardHeader>
                        <CRow>
                            <CCol md="8">
                                Edit Negara
                            </CCol>
                            <CCol md="4" class="text-right">
                                
                            </CCol>
                        </CRow>
                    </CCardHeader>
                    <CForm v-on:submit.prevent="storeProcess" class="fwdwd">
                        <CCardBody>
                            <div class="form-group">
                                <label for="">
                                    <b>Inisial Negara <span class="text-danger">*</span></b>
                                </label>
                                <input type="text" class="form-control" placeholder="Inisial Negara" v-model="n_inisial" />
                            </div>
                            <div class="form-group">
                                <label for="">
                                    <b>Nama Negara <span class="text-danger">*</span></b>
                                </label>
                                <input type="text" class="form-control" placeholder="Nama Negara" v-model="n_nama" />
                            </div>
                        </CCardBody>
                        <CCardFooter>
                            <div class="text-right">
                                <button type="submit" class="btn btn-primary text-white btn-hover-outline-primary"><i class="fa fa-save"></i> Simpan</button>
                            </div>
                        </CCardFooter>
                    </CForm>
                </CCard>
            </CCol>
        </CRow>
    </div>
</template>
<script>
    export default {
        name: "EditNegara",
        data() {
            return {
                n_inisial: "",
                n_nama: "",
                idNegara: this.$route.params.idNegara,
            };
        },
        methods: {
            storeProcess: function () {
                let self = this;
                Swal.fire({
                    title: '<i class="fa fa-refresh fa-spin"></i>',
                    text: "Loading...",
                    showConfirmButton: false,
                    allowOutsideClick: false,
                });
                axios
                    .put(
                        this.apiLink + "api/crud/negara",
                        {
                            id: this.idNegara,
                            n_inisial: this.n_inisial,
                            n_nama: this.n_nama,
                        },
                        {
                            headers: {
                                Authorization: "Bearer " + this.access_token,
                            },
                        }
                    )
                    .then(function (response) {
                        var res_data = response.data;
                        // console.log(res_data);
                        if (res_data.meta.code != "200") {
                            Swal.fire({
                                icon: "error",
                                title: "Oops...",
                                text: res_negara.data.message,
                            });
                        } else {
                            let timerInterval;
                            Swal.fire({
                                icon: "success",
                                title: "Berhasil",
                                text: "Anda akan diarahkan ke halaman master negara segera",
                                timer: 2000,
                                timerProgressBar: true,
                                showCancelButton: false,
                                showConfirmButton: false,
                                didOpen: () => {
                                    timerInterval = setInterval(() => {
                                        const content = Swal.getContent();
                                        if (content) {
                                            const b = content.querySelector("b");
                                            if (b) {
                                                b.textContent = Swal.getTimerLeft();
                                            }
                                        }
                                    }, 100);
                                },
                                willClose: () => {
                                    clearInterval(timerInterval);
                                },
                            }).then((result) => {
                                /* Read more about handling dismissals below */
                                if (result.dismiss === Swal.DismissReason.timer) {
                                    self.$router.push({name: 'AllNegara'});
                                    
                                }
                            });
                        }
                        // console.log(JSON.stringify(response.data));
                    });
            },
        },
        mounted() {
            Swal.fire({
                title: '<i class="fa fa-refresh fa-spin"></i>',
                text: "Loading...",
                showConfirmButton: false,
                allowOutsideClick: false,
            });
            axios
                .get(this.apiLink + "api/crud/negara", {
                    headers: {
                        Authorization: "Bearer " + this.access_token,
                    },
                    params: {
                        id: this.idNegara,
                    },
                })
                .then((response) => {
                    var res_negara = response.data;
                    var res_negara_data = res_negara.data[0];
                    // console.log(res_negara_data.data[0].n_inisial);
                    if (res_negara.meta.code != "200") {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: res_negara.data.message,
                        });
                    } else {
                        this.n_inisial = res_negara_data.data[0].n_inisial;
                        this.n_nama = res_negara_data.data[0].n_nama;
                        Swal.close();
                    }
                });
        },
    };
</script>